import "./ServBanner.css";

export const ServBanner = () => {
  return (
    <div className="servbanner">
      <div className="overlay"></div>
      <h1>ASESORIA CIENTIFICA EMPRESARIAL,<br/>LABORATORIO Y CAMPO</h1>
    </div>
  );
};

import "./ServGallery.css";
import img5 from "../img-5.png";
import img6 from "../img-6.jpeg";
import img7 from "../img-7.jpeg";
import img8 from "../img-8.jpeg";
import img9 from "../img-9.jpeg";
import img10 from "../img-10.jpeg";
import img11 from "../img-11.jpeg";
import img13 from "../img-13.jpeg";
import img14 from "../img-14.jpeg";
import img15 from "../img-15.jpeg";
import img16 from "../img-16.jpeg";
import img17 from "../img-17.jpeg";

export const ServGallery = () => {
  return (
    <div className="servgallery">
      <div className="cont-image">
        <img src={img6} alt="img6" />
      </div>
      <div className="cont-image">
        <img src={img11} alt="img11" />
      </div>
      <div className="cont-image">
        <img src={img8} alt="img8" />
      </div>
      <div className="cont-image">
        <img src={img9} alt="img9" />
      </div>
      <div className="cont-image">
        <img src={img7} alt="img7" />
      </div>
      <div className="cont-image">
        <img src={img15} alt="img15" />
      </div>
      <div className="cont-image">
        <img src={img10} alt="img10" />
      </div>
      <div className="cont-image">
        <img src={img5} alt="img5" />
      </div>
      <div className="cont-image">
        <img src={img14} alt="img14" />
      </div>
      <div className="cont-image">
        <img src={img13} alt="img13" />
      </div>
      <div className="cont-image">
        <img src={img16} alt="img16" />
      </div>
      <div className="cont-image">
        <img src={img17} alt="img17" />
      </div>
    </div>
  );
};
